<template>
  <!-- Item -->
  <b-col md="4" class="mt-3">
    <div class="card shadow-sm mb-3 mx-auto d-block border-0">
      <div class="card-body">
        <h5 class="card-title font-weight-bold">
          {{ item.name }}
          <span class="badge badge-secondary primary-blue">{{
            item.price
          }}</span>
        </h5>
        <p class="card-text mb-0">{{ item.description }}</p>
        <a class="stretched-link" v-b-modal="'itemModal' + item.id"></a>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "Item",
  props: ["item"],
};
</script>
<style scoped>
.card {
  width: 97.5%;
  border-color: none !important;
}
@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
}
</style>
