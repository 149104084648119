<template>
  <!-- Progress Bar -->
  <b-row no-gutters>
    <b-col class="align-self-center text-center mb-3">
      <div
        class="dot"
        v-bind:class="{ active: $store.state.category === 0 }"
      ></div>
      <div
        class="dot ml-1"
        v-bind:class="{ active: $store.state.category === 1 }"
      ></div>
      <div
        class="dot ml-1"
        v-bind:class="{ active: $store.state.category === 2 }"
      ></div>
      <div
        class="dot ml-1"
        v-bind:class="{ active: $store.state.category === 3 }"
      ></div>
      <div
        class="dot ml-1"
        v-bind:class="{ active: $store.state.category === 4 }"
      ></div>
      <div
        class="dot ml-1"
        v-bind:class="{ active: $store.state.category === 5 }"
      ></div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Nav",
};
</script>
<style scoped>
.dot {
  height: 3vh;
  width: 3vh;
  background-color: #dee2e6;
  border-radius: 50%;
  display: inline-block;
}
.active {
  background: rgb(80, 72, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5048ff",endColorstr="#3695ff",GradientType=1);
  border: none !important;
  color: white !important;
}
</style>
