<template>
  <!-- Home -->
  <b-container fluid>
    <MenuNav></MenuNav>
    <MenuModal></MenuModal>
    <MenuSubNav></MenuSubNav>
    <Progress></Progress>
    <b-row
      v-for="(items, category) in sortedMenu"
      v-bind:key="'menucategory-' + category"
    >
      <b-col cols="12" class="pt-3 pb-3 category">
        <b>{{ category }}</b>
      </b-col>
      <Item v-for="item in items" :item="item" :key="'item' + item.id"></Item>
      <ItemModal
        v-for="item in items"
        :item="item"
        :key="'itemModal' + item.id"
      ></ItemModal>
    </b-row>
    <MenuFooterNav></MenuFooterNav>
  </b-container>
</template>

<script>
import MenuNav from "../components/MenuNav.vue";
import MenuSubNav from "../components/MenuSubNav.vue";
import Progress from "../components/Progress.vue";
import MenuFooterNav from "../components/MenuFooterNav.vue";
import MenuModal from "../components/MenuModal.vue";

import Item from "../components/Item.vue";
import ItemModal from "../components/ItemModal.vue";

export default {
  name: "Home",
  components: {
    MenuNav,
    MenuSubNav,
    Progress,
    Item,
    ItemModal,
    MenuFooterNav,
    MenuModal,
  },
  computed: {
    sortedMenu: function() {
      return this._.groupBy(
        this._.pickBy(
          this.$store.state.menu.items,
          (item) => item.category_type === this.$store.state.category
        ),
        "category_name"
      );
    },
  },
};
</script>
