<template>
  <!-- Nav -->
  <b-row no-gutters>
    <b-col>
      <nav id="nav" class="navbar navbar-light mt-1 mb-1">
        <router-link
          class="font-weight-bold btn mr-auto primary-blue"
          style="color:black;"
          v-if="$store.state.category === 1"
          to="/"
          >Back</router-link
        >
        <a
          class="font-weight-bold btn mr-auto primary-blue"
          style="color:black;"
          v-if="$store.state.category > 1"
          v-on:click="back"
          >Back</a
        >
        <a
          class="font-weight-bold btn ml-auto primary-blue"
          style="color:black;"
          target="_blank"
          v-on:click="next"
        >
          <template>Next</template>
        </a>
      </nav>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "MenuNav",
  methods: {
    next: function() {
      if (this.$store.state.category <= 4) {
        this.$store.commit("addCategory");
      } else {
        this.$router.push({ name: "Status" });
      }
    },
    back: function() {
      if (this.$store.state.category >= 1) {
        this.$store.commit("removeCategory");
      }
    },
  },
};
</script>
