<template>
  <!-- Item Modal -->
  <b-modal :id="'itemModal' + item.id" header-class="hide-header">
    <!-- Item Info -->
    <h3 class="font-weight-bold">
      {{ item.name }}
      <span class="badge badge-secondary primary-blue">{{ total }}</span>
    </h3>
    <p class="mb-4">{{ item.description }}</p>

    <!-- Item Selections 
    <div class="row no-gutters mt-4">
      <div class="col-12">
        <div
          class="option-header mb-4"
          v-for="selection in item.selections"
          :key="'selection-' + selection.id"
        >
          <Selection :selection="selection"></Selection>
        </div>
      </div>
    </div>-->

    <!-- Notes Selection -->
    <h6 class="font-weight-bold">
      Special Notes
    </h6>
    <input
      type="text"
      class="form-control form-control-sm mt-3"
      v-model="special_notes"
    />

    <!-- Quantity Selection -->
    <h6 class="font-weight-bold mt-4">
      Quantity
    </h6>
    <div class="text-center mt-3">
      <button
        class="btn font-weight-bold primary-blue"
        style="vertical-align:initial;"
        v-on:click="subtractItem(item.price)"
      >
        -
      </button>
      <h5
        style="vertical-align:initial;"
        class="font-weight-bold ml-3 mr-3 d-inline"
      >
        {{ quantity }}
      </h5>
      <button
        class="btn font-weight-bold primary-blue"
        style="vertical-align:initial;"
        v-on:click="addItem(item.price)"
      >
        +
      </button>
    </div>

    <!-- Footer -->
    <template slot="modal-footer">
      <button class="btn primary-blue" v-on:click="close">
        <span class="font-weight-bold">Close</span>
      </button>
      <button class="btn primary-blue">
        <span
          class="font-weight-bold"
          v-on:click="submitItem"
          v-if="!this.loading"
        >
          Add to Order
        </span>
        <b-spinner
          small
          v-if="this.loading"
          type="grow"
          style="vertical-align:middle;"
        ></b-spinner>
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ItemModal",
  props: ["item"],
  data: function() {
    return {
      quantity: 1,
      loading: false,
      selected: [],
      special_notes: "",
    };
  },
  methods: {
    addItem: function() {
      if (this.quantity <= 9) {
        this.quantity += 1;
      }
      return;
    },
    subtractItem: function() {
      if (this.quantity >= 2) {
        this.quantity -= 1;
      }
      return;
    },
    submitItem: function() {
      this.loading = true;

      this.axios
        .post(process.env.VUE_APP_ADD_ITEM_URL, {
          vendor: this.$store.state.vendor.uuid,
          quantity: this.quantity,
          id: this.item.id,
          special_notes: this.special_notes,
        })
        .then((response) => {
          console.log(response);
          this.quantity = 1;
          this.close();
          this.loading = false;
        })
        .catch((error) => {
          this.error = error.message;
          console.error("There was an error!", error);
        });
    },
    close: function() {
      const id = "itemModal" + this.item.id;
      this.$bvModal.hide(id);
    },
  },
  computed: {
    total: function() {
      return (parseFloat(this.item.price) * this.quantity).toFixed(2);
    },
  },
};
</script>
<style scoped>
.badge {
  vertical-align: top;
}
.modal-content {
  margin-top: 0;
}
.spinner-grow {
  background: white !important;
}
</style>
<style>
.hide-header {
  display: none !important;
}
</style>
