<template>
  <!-- Receipts Modal -->
  <b-modal id="menuModal">
    <template slot="modal-title" class="mb-0 w-100">
      <h4 class="font-weight-bold w-100">
        <span class="mr-auto">Quick Nav</span>
      </h4>
    </template>
    <a v-on:click="set(1)"
      ><h4 class="font-weight-bold text-center mt-5">
        Non Alcoholic Drinks
        <span
          class="badge font-weight-bold primary-blue ml-1"
          v-if="$store.state.category === 1"
          >Here</span
        >
      </h4></a
    >
    <h4 class="text-center mt-3 mb-3 divider">|</h4>
    <a v-on:click="set(2)"
      ><h4 class="font-weight-bold text-center">
        Alcoholic Drinks
        <span
          class="badge font-weight-bold primary-blue ml-1"
          v-if="$store.state.category === 2"
          >Here</span
        >
      </h4></a
    >
    <h4 class="text-center mt-3 mb-3 divider">|</h4>
    <a v-on:click="set(3)">
      <h4 class="font-weight-bold text-center">
        Appetizers
        <span
          class="badge font-weight-bold primary-blue ml-1"
          v-if="$store.state.category === 3"
          >Here</span
        >
      </h4></a
    >
    <h4 class="text-center mt-3 mb-3 divider">|</h4>
    <a v-on:click="set(4)">
      <h4 class="font-weight-bold text-center">
        Mains
        <span
          class="badge font-weight-bold primary-blue ml-1"
          v-if="$store.state.category === 4"
          >Here</span
        >
      </h4></a
    >
    <h4 class="text-center mt-3 mb-3 divider">|</h4>
    <a v-on:click="set(5)">
      <h4 class="font-weight-bold mb-5 text-center">
        Desserts
        <span
          class="badge font-weight-bold primary-blue ml-1"
          v-if="$store.state.category === 5"
          >Here</span
        >
      </h4></a
    >
    <template slot="modal-footer">
      <button class="btn primary-blue" v-on:click="close">
        <span class="font-weight-bold">Close</span>
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "MenuModal",
  methods: {
    close: function() {
      this.$bvModal.hide("menuModal");
    },
    set: function(category) {
      this.$store.commit("setCategory", category);
      this.$bvModal.hide("menuModal");
    },
  },
};
</script>
<style scoped>
.divider {
  color: #dee2e6 !important;
}
</style>
