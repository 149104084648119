<template>
  <!-- Go to Cart -->
  <div class="fixed-bottom">
    <router-link :to="{name: 'Status'}" class="btn font-weight-bold mb-3 mt-3 text-center mx-auto d-block" v-if="!emptyCart">Go to Checkout <span class="badge font-weight-bold">{{cart.true}}</span></router-link>
  </div>
</template>

<script>
export default {
  name: 'MenuFooterNav',
  computed:{
     cart: function(){
       return this._.countBy(this.$store.state.order.items, function(item) {
          return item.status == 1;
        })
     },
     emptyCart: function () {
       return this._.isEmpty(this._.groupBy(this._.sortBy(this._.pickBy(this.$store.state.order.items, function(item) {
          return item.status == 1;
        }), 'category_type'), 'category_name'));
      },
  }
}
</script>
<style scoped>
  .btn{
  width:95%;
  }
  .badge{
    background-color:white !important;
    color:var(--main-color) !important;
    vertical-align:middle;
  }
</style>
