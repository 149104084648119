<template>
  <!-- Menu Sub Nav -->
  <b-row no-gutters>
    <b-col>
      <a v-on:click="open"
        ><h3 class="text-center font-weight-bold mt-3 mb-3">
          <template v-if="$store.state.category === 1"
            >Non Alcoholic Drinks</template
          >
          <template v-if="$store.state.category === 2"
            >Alcoholic Drinks</template
          >
          <template v-if="$store.state.category === 3">Appetizers </template>
          <template v-if="$store.state.category === 4">Mains</template>
          <template v-if="$store.state.category === 5">Desserts</template>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 16l-6-6h12z" />
          </svg></h3
      ></a>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "MenuNav",
  methods: {
    open: function() {
      this.$bvModal.show("menuModal");
    },
  },
};
</script>
